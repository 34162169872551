<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body v-if="item">

		<app-content-box>

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name" />

		</app-content-box>

		<com-rules v-model="model.rules" />

		<app-content-section v-if="!isNew" route="Library.Games" :params="{ filter: { collection: item.id } }" icon="library" title="Games" description="View the games in this collection." :count="item.count.games" :forceCount="true" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

import comRules from './librarycollection/Rules'

export default {

	mixins: [mixForm],

	components: {
		'com-rules': comRules
	},

	data: function() {

		return {
			model: {
				id: false,
				name: '',
				rules: []
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>