<template>

<app-content-box title="Rules" icon="library" class="rules">

	<template v-if="!newRule && !value.length">

		<p class="rules-text">Add one or more rules to automatically include specific shelves or games within this collection.</p>

		<app-button text="Add rule" v-on:click="onAddClick" />

	</template>

	<com-rule v-for="(rule, index) in value" :key="rule.id" :rule="rule" v-on:save="onSave(index, $event)" v-on:remove="onRemove(index)" />

	<com-rule v-if="newRule" :key="newRule.id" :rule="newRule" :is-new="true" v-on:save="onAdd($event)" />

</app-content-box>

</template>

<script>

import comRule from './Rule'

import Vue from 'vue'

export default {

	props: ['value'],

	components: {
		'com-rule': comRule
	},

	data: function() {

		return {
			newRule: false
		}

	},

	created: function() {

		if (this.value.length) this.onAddClick()

	},

	methods: {

		onAddClick: function() {

			Vue.set(this, 'newRule', {
				id: this.$moment().unix(),
				type: 1,
				value: ''
			})

		},

		onRemove: function(index) {

			var rules = this.$util.copy(this.value)

			rules.splice(index, 1)

			this.$emit('input', rules)

		},

		onAdd: function(data) {

			var rules = this.$util.copy(this.value)

			rules.push(data)

			this.$emit('input', rules)

			this.onAddClick()

		},

		onSave: function(index, data) {

			var rules = this.$util.copy(this.value)

			Vue.set(rules, index, data)

			this.$emit('input', rules)

		}

	}

}

</script>

<style scoped>

.rules >>> .box-content {
	padding: 10px;
}

.rules-text {
	font-size: 16px;
	text-align: center;
}

</style>
