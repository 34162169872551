<template>

<div class="rule" :class="{'has-changes': isChanged && !isNew}">

	<app-input-toggle v-model="model.type" :options="options" />
	<app-input-text v-model="model.value" :placeholder="placeholder" />
	<app-button class="rule-button" :disabled="!isValid" v-if="isNew" v-on:click="onSaveClick" v-tooltip="'Add rule'"><app-icon icon="rule-add" /></app-button>
	<app-button class="rule-button" v-if="!isNew && !isChanged" theme="red" v-on:click="onRemoveClick" v-tooltip="'Remove rule'"><app-icon icon="rule-remove" /></app-button>
	<app-button class="rule-button" v-if="!isNew && isChanged" v-on:click="onUndoClick" v-tooltip="'Undo changes'"><app-icon icon="rule-undo" /></app-button>
	<app-button class="rule-button" v-if="!isNew && isChanged" v-on:click="onSaveClick" v-tooltip="'Save changes'"><app-icon icon="rule-save" /></app-button>

</div>

</template>

<script>

export default {

	props: ['rule', 'isNew'],

	data: function() {

		return {
			model: {
				id: false,
				type: 1,
				value: ''
			},
			options: {
				1: 'Shelf',
				2: 'Game'
			}
		}

	},

	created: function() {

		this.model.id = this.rule.id
		this.model.type = this.rule.type 
		this.model.value = this.rule.value

	},

	computed: {

		isChanged: function() {

			return this.model.type !== this.rule.type || this.model.value !== this.rule.value

		},

		isValid: function() {

			return this.model.value

		},

		placeholder: function() {

			return (this.model.type === 1) ? 'Enter shelf numbers' : 'Enter game references'

		}

	},

	methods: {

		onUndoClick: function() {

			this.model.type = this.rule.type 
			this.model.value = this.rule.value

		},

		onSaveClick: function() {

			this.$emit('save', this.$util.copy(this.model))

		},

		onRemoveClick: function() {

			this.$emit('remove')

		}

	}

}

</script>

<style scoped>

.rule {
	display: grid;
	grid-template-columns: 200px auto 40px;
	grid-gap: 10px;
	height: 40px;
	margin-bottom: 10px;
}

.rule.has-changes {
	grid-template-columns: 200px auto 40px 40px;
}

.rule:last-child {
	margin-bottom: 0px;
}

.rule-button {
	width: 40px;
	height: 40px;
	border-radius: 10px;
	font-size: 16px;
}

</style>
